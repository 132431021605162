var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ca0f7011c2a5e59aab97485250ee4bf2f3ee07bc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole, Dedupe as DedupeIntegration } from "@sentry/integrations";

const env = process.env.NEXT_PUBLIC_APP_ENV || "development";

Sentry.init({
  dsn: env !== "development" ? process.env.NEXT_PUBLIC_SENTRY_HOST : "",
  integrations: [
    /*
      report "console.error" messages to sentry
      Ref:
        Stackoverflow: How to report console.error with Sentry?
          https://stackoverflow.com/questions/50633580/how-to-report-console-error-with-sentry
        Sentry Docs - Pluggable Integrations - CaptureConsole
          https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/#captureconsole
    */
    new CaptureConsole({
      levels: ["error"],
    }),
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        "https://rimo-backend.rimo.app",
        "https://rimo-backend.rimo-stg.app",
        "https://collab-editor-server.rimo.app",
        "https://collab-editor-server.rimo-stg.app",
        /^https:\/\/[^.]+\.asp\.rimo\.app\//,
        /^https:\/\/[^.]+\.asp\.rimo-stg\.app\//,
      ],
    }),
    new DedupeIntegration(),
  ],
  environment: env,
  release: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
  ignoreErrors: [
    // this error may be caused by MSFT crawler
    // Ref: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/25
    /.*Object Not Found Matching Id.*/,
  ],
  tracesSampleRate: process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 1.0,
  // maxBreadcrumbs: 10 is needed to avoid 413 (Request Entity Too Large)
  // if 413 error still occurs, try to implement 'before-breadcrumb' callback
  // https://docs.sentry.io/error-reporting/configuration/filtering/?platform=javascript#before-breadcrumb
  maxBreadcrumbs: 10,
});
